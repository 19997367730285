import { useRouter } from 'next/router'

import Box from '@mui/system/Box'

import { useOrganizationQuery } from '@services/graphql/generated'
import { Content, ErrorMessage, Loading, PageWrapper } from '@shared'

const Home = () => {
  const router = useRouter()
  const { organization } = router.query

  const { data, loading, error } = useOrganizationQuery({
    variables: {
      handle: organization as string,
    },
  })

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <PageWrapper
      headerTitle={`${data?.organization?.name}`}
      toolbarTitle={organization as string}
    >
      <Content>
        <Box>
          <h1>Organization: {data?.organization?.name}</h1>
          <p>
            <small>
              Org ID: <i>{data?.organization?.id}</i>
            </small>
          </p>
          <p>
            <small>
              JWT Issuer Domain: <i>{data?.organization?.jwtIssuerDomain}</i>
            </small>
          </p>
        </Box>
      </Content>
    </PageWrapper>
  )
}

export default Home
